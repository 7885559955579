import React from "react"
import CookieConsent from "react-cookie-consent"
import styled from "styled-components"
import { Link, graphql } from "gatsby"

import Header from "../components/header"
import SEO from "../components/seo"
import Footer from "../components/Footer"
import { MAIN_COLOR, SECONDARY_COLOR } from "../variables"
import Container from "../components/Container"
import Up from "../components/Up"

const StyledLink = styled(Link)`
  color: ${SECONDARY_COLOR};
`

const Main = styled.main`
  margin: 4.5454rem 0;
`

const simplePage = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <>
      <SEO title={frontmatter.title} />
      <Header siteTitle={frontmatter.title} />
      <Main>
        <Container>
          <h1>{frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Container>
      </Main>
      <CookieConsent
        location="bottom"
        buttonText="Elfogadom"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        style={{ background: MAIN_COLOR, fontSize: "0.7272em" }}
        buttonStyle={{
          backgroundColor: SECONDARY_COLOR,
          color: "#fff",
          padding: "10px 20px",
          transition: "all 0.3s ease-in-out",
          textDecoration: "none",
          borderRadius: 10,
          display: "inline-block",
          cursor: "pointer",
          fontWeight: 700,
        }}
      >
        Az oldal sütiket használ. Kérlek a jobb felhasználási élményért fogadd
        el az{" "}
        <StyledLink to="/adatvedelmi-nyilatkozat">adatvédelmi szabályzatunk</StyledLink>
      </CookieConsent>
      <Footer />
      <Up />
    </>
  )
}

export default simplePage

export const pageQuery = graphql`
  query PageQuery($id: String) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
      }
    }
  }
`
